import moment from "moment";
moment.locale("pt-BR");

class CSV {
  constructor(headers, items, name) {
    this.items = items;
    this.headers = this.transformHeaders(headers);
    this.name = `${name}_${this.dateFormatted(new Date(), true)}.csv`;
  }

  execute() {
    return this.generateCSV();
  }

  transformHeaders(headers) {
    return headers.map((header) => header.toUpperCase());
  }

  generateCSV() {
    const csvContent = this.convertDataToCSV();
    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8",
    });
    return URL.createObjectURL(blob);
  }

  convertDataToCSV() {
    const rows = this.items.map((obj) => {
      return Object.values(obj).map((value) => {
        if (typeof value === "string" && value.includes(","))
          return `\"${value}\"`;
        return value;
      });
    });
    const headerRow = this.headers.join(",");
    const csvRows = [headerRow, ...rows.map((row) => row.join(","))];
    return csvRows.join("\n");
  }
  dateFormatted(date, hasHours = false) {
    return hasHours ? moment(date).format("L LT") : moment(date).format("L");
  }
}

export default CSV;
