<template>
  <div>
    <LoadingSpinner :show="loading" />
    <div style="display: flex; justify-content: end">
      <button class="btn btn-info" @click="generateReport">
        Gerar relatório
      </button>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        filename: `relatório_vendas_${dateFormatted(new Date(), true)}`,
        margin: [4, 0, 10, 0],
        html2canvas: {
          scale: 2,
        },
      }"
    >
      <div slot="pdf-content" style="padding: 20px">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row-reverse;
          "
        >
          <img
            style="width: 128px"
            src="../../../public/img/favicon.png"
            alt="Snackly Logo"
          />
          <p class="text-dark" style="font-size: 12px">
            gerado em {{ dateFormatted(new Date()) }}
          </p>
        </div>
        <h3 class="text-dark">Gráfico de Produtos</h3>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row-reverse;
          "
        >
          <div>
            <p class="text-dark" v-show="startDate && endDate">
              Data inicial
              <span class="bold"> {{ dateFormatted(startDate) }}</span>
              <br />
              Data final <span class="bold"> {{ dateFormatted(endDate) }}</span>
            </p>
          </div>
          <div>
            <p class="items-total text-dark">
              Valor total {{ toBRL(total / 100) }}
            </p>
          </div>
        </div>

        <div
          v-for="(data, idx) in chartData"
          :key="idx"
          style="width: 100%; margin: 0 auto"
          class="avoid-break"
        >
          <apexchart
            width="100%"
            type="bar"
            :options="{
              chart: {
                id: 'productsChart',
                stacked: true,
              },
              xaxis: {
                categories: data.categories,
                labels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
              yaxis: {
                show: true,
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: true,
                  borderRadius: 10,
                  borderRadiusApplication: 'end', // 'around', 'end'
                  borderRadiusWhenStacked: 'last', // 'all', 'last'
                  dataLabels: {
                    total: {
                      enabled: true,
                      formatter: function (
                        value,
                        { seriesIndex, dataPointIndex, w }
                      ) {
                        const series = w.config.series;
                        let unitValue = 0;
                        let quantity = 0;
                        const quantitySeries = series.find(
                          (s) => s.name === 'Quantidade'
                        );
                        const unitValueSeries = data.unitPrices;
                        if (quantitySeries) {
                          quantity = quantitySeries.data[dataPointIndex] || 0;
                        }
                        if (unitValueSeries) {
                          unitValue = unitValueSeries.data[dataPointIndex] || 0;
                        }
                        return `${toBRL((unitValue / 100) * quantity)}`;
                      },
                      offsetX: 10,
                      offsetY: 0,
                      style: {
                        color: '#cb523d',
                        fontSize: '14px',
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              legend: {
                show: false,
              },
              fill: {
                opacity: 1,
              },
              colors: ['#e4c465', '#cb523d'],
              dataLabels: {
                position: 'top',
                enabled: true,
                textAnchor: 'middle',
                style: {
                  fontSize: '14px',
                  colors: ['#fff'],
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true,
                },
              },
            }"
            :series="data.series"
          ></apexchart>
        </div>

        <div style="margin-top: 72px">
          <table table id="customers">
            <thead>
              <tr>
                <th>Valor total</th>
                <th>Desconto</th>
                <th>Preço Venda</th>
                <th>Quantidade</th>
                <th>Produto</th>
                <th>Máquina</th>
              </tr>
            </thead>
            <tbody class="text-dark">
              <tr class="avoid-break" v-for="(sale, idx) in logList" :key="idx">
                <td>
                  {{
                    toBRL(((sale.price - sale.discount) * sale.quantity) / 100)
                  }}
                </td>
                <td>{{ toBRL(sale.discount / 100) }}</td>
                <td>{{ toBRL(sale.price / 100) }}</td>
                <td>{{ sale.quantity }}</td>
                <td>{{ sale.product.name }}</td>
                <td>{{ sale.machine.name }}</td>
              </tr>
              <tr>
                <td class="items-total">{{ toBRL(total / 100) }}</td>
                <td class="text-left">-----</td>
                <td class="text-left">-----</td>
                <td class="text-left">-----</td>
                <td class="text-left">-----</td>
                <td class="items-total">TOTAL</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import LoadingSpinner from "../../components/Spinner.vue";
moment.locale("pt-BR");

export default {
  name: "ReportPDF",
  components: {
    VueHtml2pdf,
    LoadingSpinner,
  },
  mounted() {},
  props: {
    total: Number,
    endDate: String,
    startDate: String,
    chartData: {},
    logList: {
      machine: {
        id: String,
        name: String,
      },
      product: {
        name: String,
        image: String,
      },
      quantity: Number,
      price: Number,
      discount: Number,
      date: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    dateFormatted(date, hasHours = false) {
      return hasHours ? moment(date).format("L LT") : moment(date).format("L");
    },
    onProgress(event) {
      if (event == 100) {
        this.loading = false;
      }
    },
    hasGenerated() {
      this.loading = false;
    },
    generateReport() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="css" scoped>
.avoid-break {
  page-break-inside: avoid;
}
.items-total {
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

#customers td,
#customers th {
  padding: 4px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #cb523d;
  color: white;
}
</style>
