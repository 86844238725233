<template>
  <div>
    <card class="p-4" title="Reposição de produtos" v-if="selectedMachine">
      <div>
        <p>Relatório estoque mínimo</p>
        <a
          class="badge-donwload -pdf mr-2"
          @click="handleGenerateReports({ type: 'pdf' })"
          >PDF</a
        >
        <a
          class="badge-donwload -excel"
          ref="RefCSVReportLink"
          @click="handleGenerateReports({ type: 'csv' })"
          >Excel</a
        >
      </div>
      <PDF
        :pdfHeaders="pdfHeader"
        name="Relatório Estoque Mínimo"
        ref="pdfReport"
        :data="csvMetricData"
      />
      <LoadingSpinner :show="loading" />
      <table class="table table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col">Produto</th>
            <th scope="col">Quant. estoque</th>
            <th scope="col">Média vendas</th>
            <th scope="col">Estoque mínimo</th>
            <th scope="col">Quant. reposição</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product of products" :key="product.product_id">
            <td>
              <ProductImage :size="54" :src="product.image" />
            </td>
            <td>
              <p class="font-weight-bold">{{ product.title }}</p>
            </td>
            <td>
              <p class="font-weight-bold">{{ product.stock_quantity }}</p>
            </td>
            <td>
              <p class="font-weight-bold">{{ product.sold_quantity }}</p>
            </td>
            <td>
              <p class="font-weight-bold">{{ product.minimum_stock }}</p>
            </td>
            <td>
              <p class="font-weight-bold">
                {{ product.replacement_quantity }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <div v-else>
      <SelectMachineInventory @selected-machine="receiveEmitMachineSelected" />
    </div>
  </div>
</template>
<script>
import CSV from "../services/CSV";
import LoadingSpinner from "../components/Spinner.vue";
import ProductImage from "../components/ProductImage.vue";
import SelectMachineInventory from "../components/SelectMachineInventory.vue";
import PDF from "../components/PDF/PDF.vue";

export default {
  components: {
    LoadingSpinner,
    ProductImage,
    SelectMachineInventory,
    PDF,
  },
  data() {
    return {
      loading: false,
      company_id: "",
      warehouse_id: "",
      products: [],
      selectedMachine: null,
      csvMetricData: [],
      pdfHeader: [
        "Produto",
        "Quant. Estoque",
        "Media. Vendas",
        "Est. Mínimo",
        "Quant. Reposição",
      ],
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
  },
  methods: {
    async getStockProductMetrics() {
      try {
        if (!this.selectedMachine) {
          this.$toast.warning("Selecione uma máquina");
          return;
        }
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          machine_id: this.selectedMachine,
        };
        this.loading = true;
        const { data } = await this.$http.get("/stock/metric", {
          params,
        });
        this.products = data.metrics;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleGenerateReports(report) {
      if (report.type == "pdf") {
        this.pdfReport();
      } else {
        this.csvReport();
      }
    },
    pdfReport() {
      this.csvMetricData = this.products.map((product) => {
        return {
          title: product.title,
          stock_quantity: product.stock_quantity,
          sold_quantity: product.sold_quantity,
          minimum_stock: product.minimum_stock,
          replacement_quantity: product.replacement_quantity,
        };
      });
      this.$refs.pdfReport.$refs.html2Pdf.generatePdf();
    },
    csvReport() {
      const csvMetricData = this.products.map((product) => {
        return {
          title: product.title,
          stock_quantity: product.stock_quantity,
          sold_quantity: product.sold_quantity,
          minimum_stock: product.minimum_stock,
          replacement_quantity: product.replacement_quantity,
        };
      });
      const csvMetrics = new CSV(
        this.pdfHeader,
        csvMetricData,
        "Relatório Estoque Mínimo"
      );
      this.$refs.RefCSVReportLink.href = csvMetrics.execute();
      this.$refs.RefCSVReportLink.download = csvMetrics.name;
    },
    receiveEmitMachineSelected(data) {
      this.selectedMachine = data.machine_id;
    },
  },
  watch: {
    selectedMachine() {
      this.getStockProductMetrics();
    },
  },
};
</script>

<style lang="css" scoped>
.badge-donwload {
  padding: 0.4rem 0.6rem;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
}

.badge-donwload:visited {
  color: #fff;
}

.badge-donwload.-pdf {
  background-color: #f95738;
}

.badge-donwload.-excel {
  background-color: #57cc99;
}
</style>